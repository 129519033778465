<template>
  <b-container>
    <b-row>
      <b-col cols="8">
        <b-card>
          <label for="details" class="text-primary font-weight-bold mb-1"
            >Detail</label
          >
          <b-form v-on:submit.prevent>
            <b-row>
              <b-col cols="6">
                <b-form-group label="Warehouse" label-for="warehouse">
                  <b-form-input
                    id="warehouse"
                    v-model="form.warehouse"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Code" label-for="code">
                  <b-form-input id="code" v-model="form.code" disabled />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Product" label-for="product">
                  <b-form-input id="product" v-model="form.product" disabled />
                </b-form-group>
              </b-col>
            </b-row>
            <!-- <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mt-1"
              @click="updateWarehouseItem()"
            >
              Update
            </b-button> -->
          </b-form>
        </b-card>
        <b-row class="mb-2">
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              block
              v-b-modal.modal-increase
            >
              Add Stock
            </b-button>
            <b-modal id="modal-increase" title="Add Stock" hide-footer>
              <b-form v-on:submit.prevent>
                <b-form-group>
                  <b-form-group label="Qty" label-for="qty">
                    <b-form-input
                      id="qty"
                      placeholder="qty"
                      v-model="insertForm.qty"
                      required
                    />
                  </b-form-group>
                </b-form-group>
                <b-form-group label="Description" label-for="description">
                  <b-form-textarea
                    id="description"
                    placeholder="Description"
                    v-model="insertForm.description"
                  />
                </b-form-group>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="success"
                  class="my-2"
                  block
                  @click="insertItem()"
                >
                  Submit
                </b-button>
              </b-form>
            </b-modal>
          </b-col>
        </b-row>
        <b-card>
          <label for="details" class="text-primary font-weight-bold mb-1"
            >History</label
          >
          <b-row>
            <b-col>
              <v-select
                id="bin"
                v-model="filter.selectedBin"
                :options="filter.optionsBin"
                label="text"
                class="bg-light-primary"
              />
              <!-- Selected: <strong>{{ filter.selectedBin }}</strong> -->
            </b-col>
            <b-col class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Search</label>
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Search"
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- table -->
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
            :select-options="{
              enabled: false,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
          >
            <template slot="table-row" slot-scope="props">
              <!-- Column: Name -->
              <span v-if="props.column.field === 'name'" class="text-nowrap">
                <span class="text-nowrap">{{ props.row.name }}</span>
              </span>

              <!-- Column: Date -->
              <span
                v-if="props.column.field === 'created_at'"
                class="text-nowrap"
              >
                <span class="text-nowrap">{{ moment(props.row.created_at) }}</span>
              </span>

              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      v-bind:to="{
                        path: '/warehouse/item/' + props.row.id,
                      }"
                    >
                      <feather-icon icon="Edit2Icon" class="mr-50" />
                      <span>Detail</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3', '5', '10']"
                    class="mx-1"
                    @input="
                      (value) => props.perPageChanged({ currentPerPage: value })
                    "
                  />
                  <span class="text-nowrap">
                    of {{ props.total }} entries
                  </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="
                      (value) => props.pageChanged({ currentPage: value })
                    "
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card>
          <label for="details" class="text-primary font-weight-bold"
            >Product Detail</label
          >
          <b-row>
            <b-col>
              <label class="mb-1">Image</label>
              <b-img
                id="image"
                :src="photo"
                fluid
                alt="Product Image"
                v-b-modal.modal-lg
                class="mb-2"
              />
              <b-modal
                id="modal-lg"
                ok-only
                ok-title="close"
                centered
                size="lg"
                title="Large Modal"
                class="text-center"
              >
                <b-img
                  id="image"
                  :src="photo"
                  width="1000px"
                  fluid
                  alt="product image"
                />
              </b-modal>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4"> Code </b-col>
            <b-col md="8" class="text-right text-primary font-weight-bold">
              {{ product.code }}
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col md="4"> Name </b-col>
            <b-col md="8" class="text-right text-primary font-weight-bold">
              {{ product.name }}
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col md="4"> Category </b-col>
            <b-col md="8" class="text-right text-primary font-weight-bold">
              {{ product.category_name }}
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col md="4"> Qty </b-col>
            <b-col md="8" class="text-right text-primary font-weight-bold">
              {{ form.qty }} {{ product.unit_name }}
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BImg,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormSelect,
  BListGroupItem,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BContainer,
  BModal,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import { $themeConfig } from "@themeConfig";
import moment from "moment";
import vSelect from "vue-select";

export default {
  components: {
    VueGoodTable,
    ToastificationContent,
    vSelect,
    BImg,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormSelect,
    BListGroupItem,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    Prism,
    BContainer,
    BModal,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      columns: [
        {
          label: "Quantity",
          field: "qty",
          width: "175px",
        },
        {
          label: "Datetime",
          field: "created_at",
          width: "175px",
        },
        {
          label: "Description",
          field: "description",
        },
        // {
        //     label: 'Action',
        //     field: 'action',
        //     width: '100px'
        // },
      ],
      filter: {
        selectedBin: { value: "", text: "All" },
        optionsBin: [],
      },
      search: "",
      rows: [],
      searchTerm: "",
      form: {
        warehouse: "",
        product: "",
        vendor: "",
        code: "",
        qty: null,
        product_id: null,
      },
      insertForm: {
        qty: null,
        description: null,
      },
      product: {
        code: "",
        name: "",
        category_name: "",
        unit_name: "",
      },
      permissions: [],
      photo: "",
      products: [],
      errors: "",
      errMessage: "",
    };
  },
  setup() {
    // App Name
    const { baseUrl, apiUrl } = $themeConfig.app;
    return {
      baseUrl,
      apiUrl,
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        1: "light-success",
        0: "light-secondary",
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  watch: {
    "filter.selectedBin": function (data) {
      this.getLogs(data.value);
    },
  },
  created() {
    this.getAllPermissions();
    this.getDatas();
    this.getLogs("");
  },
  methods: {
    moment(date) {
      return moment(date).format("DD-MM-YYYY, hh:mm");
    },
    getAllPermissions() {
      this.permissions = sessionStorage.getItem("permissions");
    },
    getDatas() {
      const id = this.$route.params.id;
      this.$http.get("warehouse/item/" + id).then((response) => {
        // console.log(response.data.data)
        this.form.warehouse = response.data.data.warehouse_name;
        this.form.product = response.data.data.product_name;
        this.form.code = response.data.data.code;
        this.form.product_id = response.data.data.product_id;
        this.form.qty = response.data.data.qty;
        this.getDetailsProduct();
      });
    },
    getDetailsProduct() {
      this.$http.get("product/" + this.form.product_id).then((response) => {
        // console.log(response.data.data)
        this.product.code = response.data.data.code;
        this.product.name = response.data.data.name;
        this.product.category_name = response.data.data.category_name;
        this.product.unit_name = response.data.data.unit_name;
        const { baseUrl } = $themeConfig.app;
        this.photo = baseUrl;
        this.photo += "storage/product/";
        this.photo += response.data.data.image;
        // console.log(this.photo)
      });
    },
    getLogs() {
      const id = this.$route.params.id;
      this.$http.get("warehouse/item/" + id + "/logs").then((response) => {
        // console.log(response.data.data)
        this.rows = response.data.data;
      });
    },
    updateWarehouseItem() {
      const id = this.$route.params.id;
      this.$http
        .post("warehouse/item/" + id + "/update", {
          warehouse_id: id,
          product_id: this.form.product_id,
          code: this.form.code,
        })
        .then((response) => {
          // console.log(response.data.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Success update Code",
              variant: "success",
            },
          });
          location.reload();
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    insertItem() {
      const id = this.$route.params.id;
      this.$http
        .post("warehouse/item/insert", {
          item_id: id,
          qty: this.insertForm.qty,
          description: this.insertForm.description,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Item Stock Updated",
              variant: "success",
            },
          });
          location.href = "/warehouse/item/" + id;
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "success",
            },
          });
          console.log(errors.response);
        });
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>